<template>
  <div class="w-full px-2">
    <div class="flex flex-wrap -mx-2">
      <div class="w-full xs:w-full bg sm:w-full px-2 mb-2">
        <div class="vx-row bg-white w-full">
          <div class="w-full p-2">
            <span class="text-primary text-2xl font-bold">
              WRO Categories
            </span>
          </div>
        </div>
        <div class="vx-row bg-white w-full">
          <div class="w-full p-2">
            <span class="text-grey-light text-xl">
              Overview
            </span>
          </div>
        </div>
        <div class="vx-row bg-white w-full">
          <div class="w-full p-2">
            <span class="text-grey-light text-lg">
              This year our competitions are for everyone. .We have two main categories each with three sub competitions depending on the age group of the students (Elementary, Junior, and Senior)
            </span>
          </div>
        </div>
        <div class="vx-row bg-white w-full">
          <div class="w-full p-2">
            <span class="text-grey-light text-lg">
              <ul style="list-style:disc">
                <li v-for="competition in competitions">{{ competition.name }}</li>
              </ul>
            </span>
          </div>
        </div>
      </div>

      <div class="vx-row bg-white w-full mt-2">
        <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 mb-4" v-for="(competition, index) in competitions">
          <div class="vx-col rounded-tl-5xl	rounded-tr-5xl w-full h-78" type="filled"
               :class="{'rounded-bl-5xl': index % 2 ==0, 'rounded-br-5xl': index % 2 !=0}"
               :style="{backgroundColor: competition.color? competition.color: '#0093d2'}">
            <div class="vx-row items-center justify-center ">
              <span class="text-3xl mt-8 mb-2" :class="{'text-white': competition.color != '#ffffff'}">
                <!--{{ competition.name }}-->
              </span>
            </div>
            <div style="position: relative;
top: -15px;" class="vx-row mt-8 items-center justify-center ">
              <img class="competition-image" :src="competition.image">
            </div>
            <div class="vx-row items-center justify-center">
              <router-link :to="{path: 'competitions/' + competition.id, query: {name: competition.name}}"
                           class="text-md mt-4 mb-2"
                           :class="{'text-white': competition.color != '#ffffff'}"
                           v-if="competition.active == 1">
                view more
              </router-link>
              <span v-else class="text-md mt-4 mb-2 cursor-pointer"
                    :class="{'text-white': competition.color != '#ffffff'}">
               Stay tuned
              </span>
            </div>
          </div>
        </div>
        <!--        <div class="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 ">
                  <div class="vx-col bg-success rounded-br-5xl rounded-tr-5xl	rounded-tl-5xl w-full h-78" type="filled">
                    <div class="vx-row items-center justify-center ">
                      <span class="text-3xl mt-8 mb-2 text-white">
                        Super Category
                      </span>
                    </div>
                    <div class="vx-row mt-8 items-center justify-center ">
                      <img width="112" src="@/assets/images/important/super.png">
                    </div>
                    <div class="vx-row items-center justify-center ">
                      <span
                        class="text-md mt-4 mb-2 text-white cursor-pointer"
                      >
                       Stay tuned
                      </span>
                    </div>
                  </div>
                </div>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Competitions',
  data() {
    return {
      competitions: null
    }
  },
  mounted() {
    this.$API.competitions.getCompetitions()
      .then(response => {
        this.competitions = response
      })
  }
};
</script>

<style scoped>
.competition-image {
  width: auto;
  height: 140px;
  max-width: 100%;
}
</style>
